<template>
  <b-container>
    <b-row>
      <b-col>
        <h5>قوانین و شرایط استفاده از خدمات مایندولوژی؛</h5>
        <p>
          مالکیت مایندولوژی متعلق به شرکت مبتکران داده لیان با شماره ثبت 576288
          می باشد.
        </p>
        <p>
          ما در مایندولوژی برآنیم تا با استفاده از خدمات نرم افزاری، خدمات تخصصی
          در حوزه سلامت روان را به بهترین نحو ارائه دهیم.
        </p>
        <p>
          ورود کاربران به وب سایت و اپلیکیشن مایندولوژی و استفاده از نرم
          افزارهای کاربردی به معنای پذیرش قوانین و مقررات می باشد.
        </p>
        <p class="text-danger">
          حفظ اطلاعات جهت ایجاد فضایی کاملا ایمن و با آرامش برای کاربران از
          اولویتهای مایندولوژی می باشد
        </p>
        <h5>قوانین و مقررات عمومی:</h5>
        <p>
          کلیه اصول و رویه های مایندولوژی با قوانین جمهوری اسلامی ایران منطبق می
          باشد. درصورت تغییر قوانین و مقررات، تغییرات در همین صفحه به روزرسانی و
          اعمال میگردد و استفاده هر بار کاربران از خدمات به منزله پذیرش این
          قوانین می باشد.
        </p>
        <ul>
          <li>
            هر کاربر می بایست دارای یک حساب کاربری با شماره تلفن همراه و ایمیل
            متعلق به خود باشد که در آن تمام اطلاعات شخصی را به درستی و درنهایت
            صحت و صداقت جهت تشخیص هرچه بهتر مشاور و پزشک درج نماید. مایندولوژی
            نیز تعهد مینماید تمامی این اطلاعات را نزد خود نگه داشته و از افشای
            آنها به جزء با درخواست خود کاربر فقط برای آگاهی مشاورین ؛ جلوگیری
            نماید.
          </li>
          <li>
            مسئولیت تمامی فعالیتهایی که با حساب کاربری شخصی صورت میگیرد و همچنین
            حفظ اطلاعات حساب کاربری متوجه خود کاربر می باشد.
          </li>
          <li>
            هرگونه بی احترامی و توهین در مایندولوژی و از جانب هر شخصی پیگرد
            قانونی دارد.
          </li>
          <li>
            درخواست شماره تماس از پزشکان و مشاورین و بالعکس غیرقانونی بوده و
            توسط تیم پشتیبانی پیگیری می شود.
          </li>
          <li>
            حضور به موقع و سر وقت در زمان تعیین شده ضروری می باشد و درصورتی عدم
            حضور باید 24 ساعت قبل اطلاع رسانی شود.
          </li>
          <li>
            درصورت عدم پاسخگویی پزشک در زمان مقرر، وجه پرداخت شده عودت داده می
            شود و یا با رضایت خود کاربر مشاوره به زمانی دیگر و یا به پزشک و یا
            مشاور دیگری ارجاع داده می شود.
          </li>
          <li>
            اطلاعات حساس و هویتی کاربران و مشاوران بصورت رمزنگاری شده بر روی
            سرورهای مایندولوژی ذخیره می شود و تنها توسط مایندولوژی و یا با دستور
            قضایی قابل بازنگری می باشد.
          </li>
          <li>تمامی شئونات اخلاقی در تماسهای تصویری باید رعایت شود.</li>
          <li>
            در صورت بروز مشکل امکان استرداد هزینه های پرداخت شده جهت استفاده از
            خدمات تنها در 48 ساعت اول قابل پیگیری بوده و بعد از آن بررسی نمیشود.
          </li>
          <li>
            پرداخت هزینه های مشاوره فقط از روشهای که شرکت مشخص می کند امکان پذیر
            بوده و درغیر اینصورت غیر قابل پیگیری می باشد.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h5>قوانین و مقرارات مختص پزشک و مشاور:</h5>
        <ul>
          <li>
            ارسال هرگونه مطلب در صفحه مجازی شخصی نباید مغایر با قانون کپی رایت
            باشد و مسئولیت حقوقی مطالب منتشر شده به عهده شخص مشاور و پزشک می
            باشد.
          </li>
          <li>
            هرگونه تبلیغ و فعالیت سیاسی در حساب شخصی مایندولوژی ممنوع می باشد.
          </li>
          <li>
            هرگونه تسویه حساب فقط از طریق حساب تائید شده توسط شرکت امکانپذیر می
            باشد.
          </li>
          <li>
            کلیه مسئولیتهای ناشی از توصیه ها، مشاوره و معالجه متوجه شخص پزشک می
            باشد و مایندولوژی هیچگونه تعهدی در قبال آن ندارد.
          </li>
          <li>
            درصورت عدم حضور مراجعه کننده در زمان مقرر، پزشک و مشاور میتواند با
            زدن کلید لغو مشاوره هزینه را به کاربر استرداد دهد. هرچند این عمل از
            سمت پزشک اختیاری بوده و بعنوان یک عمل انسان دوستانه و در جهت
            رضایتمندی کاربران می باشد.
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>
